<template>
  <v-dialog
    v-if="namespace === module"
    v-model="dialog"
    :hide-overlay="!dialog"
    persistent
    :max-width="width"
  >
    <router-view name="modal" />
  </v-dialog>
</template>
<script type="text/javascript">
export default {
  props: {
    dialog: { type: Boolean, required: true },
    module: { type: String, required: false, default: "dashboard" },
    width: { type: String, required: false, default: "80%" }
  },
  computed: {
    namespace() {
      return this.$route.meta.namespace || "";
    }
  }
};
</script>
